import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Login } from './pages/Login/Login';
import { SignupCamper } from './pages/Signup/SignupCamper';
import { SignupHost } from './pages/Signup/SignupHost';
import { CampDetails } from './pages/CampingArea/Details';
import 'leaflet/dist/leaflet.css';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { AvailableLodges } from './pages/AvailableLodges/AvailableLodges';
import {CheckoutInitial} from './pages/Checkout/CheckoutInitial';
import ProfilePage from './pages/Personal/Profile/Profile';
import FavoritesPage from './pages/Personal/Favorites/Favorites';
import MyReservations from './pages/Personal/Reservations/Reservations';
import { ForgotPassword } from './pages/ForgotPass/ForgotPassword';
import { ResetPassword } from './pages/ForgotPass/ResetPassword';
import { CheckoutSuccess } from './pages/Checkout/CheckoutSuccess';
import { CheckoutFail } from './pages/Checkout/CheckoutFail';
import { AdminDashboardMain } from './pages/AdminDashboard/Home/AdminDashboardMain';
import { UsersManagement } from './pages/AdminDashboard/Users/UsersManagement';
import { CampsManagement } from './pages/AdminDashboard/Camps/CampsManagement';
import { EditCamp } from './pages/AdminDashboard/Camps/EditCamp';
import { NewCamp } from './pages/AdminDashboard/Camps/NewCamp';
import { NewUser } from './pages/AdminDashboard/Users/NewUser';
import { EditUser } from './pages/AdminDashboard/Users/EditUser';
import { NewHost } from './pages/AdminDashboard/Users/NewHost';
import { EditHost } from './pages/AdminDashboard/Users/EditHost';
import { HostDashboardMain } from './pages/HostDashboard/Home/HostDashboardMain';
import { HostProfile } from './pages/HostDashboard/Profile/HostProfile';
import { HostCampsManagement } from './pages/HostDashboard/Camps/CampsManagement';
import { NewCampHost } from './pages/HostDashboard/Camps/NewCampHost';
import { EditCampHost } from './pages/HostDashboard/Camps/EditCampHost';
import { CampsComments } from './pages/HostDashboard/Camps/CampsComments';
import { ReservationHost } from './pages/HostDashboard/Reservations/reservationsHost';
import { HostsManagement } from './pages/AdminDashboard/Users/HostsManagement';
import { ReservationManagement } from './pages/AdminDashboard/Reservations/ReservationManagement';
import { NewReservation } from './pages/AdminDashboard/Reservations/NewReservation';
import { EditReservation } from './pages/AdminDashboard/Reservations/EditReservation';
import { EditCategory } from './pages/AdminDashboard/Categories/EditCategory';
import { NewCategory } from './pages/AdminDashboard/Categories/NewCategory';
import { CategoriesManagement } from './pages/AdminDashboard/Categories/CategoriesManagement';
import { AvailableTours } from './pages/AvailableTours/AvailableTours';
import { TourDetails } from './pages/CampingTour/Details';
import { TourDashboardMain } from './pages/TourDashboard/Home/TourDashboardMain';
import { TourProfile } from './pages/TourDashboard/Profile/TourProfile';
import { TicketsTour } from './pages/TourDashboard/Tickets/ticketsTour';
import { HostToursManagement } from './pages/TourDashboard/Tours/ToursManagement';
import { NewTourHost } from './pages/TourDashboard/Tours/NewTourHost';
import { EditTourHost } from './pages/TourDashboard/Tours/EditTourHost';
import { ToursComments } from './pages/TourDashboard/Tours/ToursComments';
import { CheckoutTourInitial } from './pages/CheckoutTicket/CheckoutInitial';
import { TicketsManagement } from './pages/AdminDashboard/Tickets/TicketManagement';
import { AdminToursManagement } from './pages/AdminDashboard/Tours/ToursManagement';
import { NewTourAdmin } from './pages/AdminDashboard/Tours/NewTourAdmin';
import { EditTourAdmin } from './pages/AdminDashboard/Tours/EditTourAdmin';
import MyTickets from './pages/Personal/Tickets/Tickets';
import { UnauthPage } from './pages/ErrorPage/UnauthPage';
import { ContactUs } from './pages/ContactUs/ContactUs';
import { TransactionError } from './pages/AdminDashboard/TransactionError/TransactionError';
import { TransactionDump } from './pages/AdminDashboard/TransactionDump/TransactionDump';
import { EditTickets } from './pages/AdminDashboard/Tickets/EditTicket';
import { BlogDetails } from './pages/Blogs/Details';
import { AllBlogs } from './pages/Blogs/AllBlogs';
import { BlogManagement } from './pages/AdminDashboard/Blogs/BlogManagement';
import { NewBlog } from './pages/AdminDashboard/Blogs/NewBlog';
import { EditBlog } from './pages/AdminDashboard/Blogs/EditBlog';
import { VerifyUser } from './pages/VerifiyUser/VerifyUser';
import { SignupTour } from './pages/Signup/SignupTour';
import { Aboutus } from './pages/Aboutus/Aboutus';
import { HelmetProvider } from 'react-helmet-async';
function App() {
	return (
		<HelmetProvider>
			<Router>
				<Routes>
					<Route path='login' element={<Login />} />
					<Route path='signup-camper' element={<SignupCamper />} />
					<Route path='signup-host' element={<SignupHost />} />
					<Route path='signup-tour' element={<SignupTour />} />
					<Route path='camp/:id' element={<CampDetails/>} />
					<Route path='home' element={<Home />} />
					<Route path='about' element={<Aboutus />} />
					<Route path='/' element={<Home />} />
					<Route path='camps' element={<AvailableLodges/>}/>
					<Route path='tours' element={<AvailableTours/>}/>
					<Route path='tour/:id' element={<TourDetails/>} />
					<Route path='contact' element={<ContactUs/>} />
					<Route path='blogs' element={<AllBlogs/>} />
					<Route path='blogs/:id' element={<BlogDetails/>} />
					{/* <Route path='checkout/query' element={<CheckoutInitial/>}/>
					<Route path='checkout/queryTour' element={<CheckoutTourInitial/>}/>
					<Route path='checkout/success' element={<CheckoutSuccess/>}/>
					<Route path='checkout/fail' element={<CheckoutFail/>}/> */}
					<Route path='profile' element={<ProfilePage/>}/>
					<Route path='favorites' element={<FavoritesPage/>}/>
					<Route path='reservations' element={<MyReservations/>}/>
					<Route path='tickets' element={<MyTickets/>}/>
					<Route path='forget-pass' element={<ForgotPassword/>}/>
					<Route path='reset-pass/:id/:passwordResetCode' element={<ResetPassword/>}/>
					<Route path='verify/:user_id/:verificationCode' element={<VerifyUser/>}/>
					
					<Route path='admin/dashboard' element={<AdminDashboardMain/>}/>
					<Route path='admin/users' element={<UsersManagement/>}/>
					<Route path='admin/users/new' element={<NewUser/>}/>
					<Route path='admin/users/:id' element={<EditUser/>}/>
					<Route path='admin/hosts/new' element={<NewHost/>}/>
					<Route path='admin/hosts' element={<HostsManagement/>}/>
					<Route path='admin/hosts/:id' element={<EditHost/>}/>
					<Route path='admin/camps' element={<CampsManagement/>}/>
					<Route path='admin/camps/:id' element={<EditCamp/>}/>
					<Route path='admin/camps/new' element={<NewCamp/>}/>
					<Route path='admin/tickets' element={<TicketsManagement/>}/>
					<Route path='admin/tickets/:id' element={<EditTickets/>}/>
					<Route path='admin/tickets/new' element={<NewCamp/>}/>
					<Route path='admin/reservations' element={<ReservationManagement/>}/>
					<Route path='admin/reservations/:id' element={<EditReservation/>}/>
					<Route path='admin/reservations/new' element={<NewReservation/>}/>
					<Route path='admin/categories' element={<CategoriesManagement/>}/>
					<Route path='admin/categories/:id' element={<EditCategory/>}/>
					<Route path='admin/categories/new' element={<NewCategory/>}/>
					<Route path='admin/camps/comments/:id' element={<CampsComments/>}/>
					<Route path='admin/transaction/dump' element={<TransactionDump/>}/>
					<Route path='admin/transaction/error' element={<TransactionError/>}/>
					<Route path='admin/blogs' element={<BlogManagement/>}/>
					<Route path='admin/blogs/:id' element={<EditBlog/>}/>
					<Route path='admin/blogs/new' element={<NewBlog/>}/>
					<Route path='admin/tours' element={<AdminToursManagement/>}/>
					<Route path='admin/tours/comments/:id' element={<ToursComments/>}/>
					<Route path='admin/tours/:id' element={<EditTourAdmin/>}/>
					<Route path='admin/tours/new' element={<NewTourAdmin/>}/>


					<Route path='host/dashboard' element={<HostDashboardMain/>}/>
					<Route path='host/profile' element={<HostProfile/>}/>
					<Route path='host/camps' element={<HostCampsManagement/>}/>
					<Route path='host/camps/comments/:id' element={<CampsComments/>}/>
					<Route path='host/camps/:id' element={<EditCampHost/>}/>
					<Route path='host/camps/new' element={<NewCampHost/>}/>
					<Route path='host/reservations' element={<ReservationHost/>}/>

					<Route path='tour/dashboard' element={<TourDashboardMain/>}/>
					<Route path='tour/profile' element={<TourProfile/>}/>
					<Route path='tour/tours' element={<HostToursManagement/>}/>
					<Route path='tour/tours/comments/:id' element={<ToursComments/>}/>
					<Route path='tour/tours/:id' element={<EditTourHost/>}/>
					<Route path='tour/tours/new' element={<NewTourHost/>}/>
					<Route path='tour/tickets' element={<TicketsTour/>}/>
					<Route path='unauthorized' element={<UnauthPage/>}/>
					<Route path='error' element={<ErrorPage/>}/>

					<Route path='*' element={<ErrorPage/>}/>
				</Routes>
			</Router>
		</HelmetProvider>
	);
}

export default App;
