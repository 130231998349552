import React, { useState } from 'react';
import { Footer,Navbar} from '../../common';
import { useNavigate } from "react-router-dom";
import Face6Icon from '@mui/icons-material/Face6';
import Face5Icon from '@mui/icons-material/Face5';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BadgeIcon from '@mui/icons-material/Badge';
import { Domain } from '../../Domain';
import {Button,Modal} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isAtLeast16YearsOld } from '../../utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const SignupTour = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [name,setName] = useState('');
    const [surname,setSurname] = useState('');
    const [password,setPassword] = useState('');
    const [confirm,setConfirm] = useState('');
    const [birthdate,setBirthdate] = useState(new Date());
    const [iban,setIban] = useState('TR');
    const [bankName,setBankName] = useState('');
    const [NID,setNationalId] = useState('');
    const [email,setEmail]= useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        window.location.reload();
    }
    const handleShow = () => setShow(true);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');
    const [icon, setIcon] = useState('on');
    const [type,setType] = useState('password');
    const [icon2, setIcon2] = useState('on');
    const [type2,setType2] = useState('password');
    const handleToggle = () => {
        if (type==='password'){
           setIcon('off');
           setType('text')
        } else {
           setIcon('on')
           setType('password')
        }
     }
     const handleToggle2 = () => {
        if (type2==='password'){
           setIcon2('off');
           setType2('text')
        } else {
           setIcon2('on')
           setType2('password')
        }
     }


    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Remove "TR" if it's already in the input to handle only the numeric part
        let numericPart = inputValue.replace(/^TR/, '');

        // Ensure the remaining part is numeric
        if (/^\d*$/.test(numericPart)) {
            setIban("TR" + numericPart);
        }
    };
    const handleTCChange = (e) => {
        const inputValue = e.target.value;

        // Only allow numbers
        if (/^\d*$/.test(inputValue)) {
            setNationalId(inputValue);
        }
    };

    const allowSubmit = ()=>{
        if(name.length < 3){
            return false;
        }
        else if(surname.length<3){
            return false;

        }
        else if(password.length<8){
            return false;

        }
        else if(confirm.length<8){
            return false;
        }
        else if(!validateEmail(email)){
            return false;
        }
        else if(password !== confirm){
            return false
        }
        else if(NID.length !== 11 ){
            return false;
        }
        else if(iban.length !== 26){
            return false;
        }
        else if(bankName.length < 3 ){
            return false;
        }
        else if(!isAtLeast16YearsOld(birthdate)){
            return false
        }
        else{
            return true;
        }
    }


   const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const Signup = ()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
            "firstName": name,
            "lastName": surname,
            "email": email,
            "password": password,
            "passwordConfirmation": confirm,
            "role": "campingTour",
            "birthday": birthdate,
            "bankAcountName":bankName,
            "IBAN": iban,
            "nationalID": NID,
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/users`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            if(result.email){
                setMessage(t('AccountCreatedMessage'));
                setSuccess(true);
                handleShow();
            }
            else{
                setMessage(t('ErrorMessage'));
                setSuccess(false);
                handleShow();
            }
        })
        .catch(error => {
            setMessage(t('ErrorMessage'));
            setSuccess(false);
            handleShow();
        });
    }
    return (
            <div className='login-area bg-retarded'>
                <div className="guest-area-top">
                    <div className="container">
                        <div className="guest-header">
                            <Navbar isSearchVisible={false}/>
                            <div className="guest-top-title mt-2 p-2 ">{t('SignupHeader')}</div>
                            <p className='p-2'>{t('Signup8T')}</p>
                        </div>
                    </div>
                </div>
                <div  className="container mb-3">
                    <div className="row gx-0">
                        <div className="col-lg-6 order-1">
                            <div className="login-left-area">
                                <span style={{fontWeight:500}}>{t('Signup9')}</span>
                                <div className="login-left-title">{t('Signup10')}</div>
                                <a  onClick={()=>{navigate('../signup-camper')}} className="button brown large"> {t('Signup6btn')}</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2">
                            <div className="login-right-area">
                                <div className="guest-login-area">
                                    <div className="guest-login-body">
                                    <div className="form-area">
                                            <p>{t("FirstName")}</p>
                                            <input type="text" onChange={(e)=>{setName(e.target.value)}} placeholder={t('placeHolderFirstname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <Face5Icon className='mt-3'/>
                                            { <>{name.length>2 ? <></> :<span style={{color: "red"}}>{t('warningName')} </span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("LastName")}</p>
                                            <input type="text" onChange={(e)=>{setSurname(e.target.value)}} placeholder={t('placeHolderLastname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <Face6Icon className='mt-3'/>
                                            { <>{surname.length>2 ? <></> :<span style={{color: "red"}}>{t('warningName')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("mail1")}</p>
                                            <input type="text" onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} placeholder={t('placeHolderEmail')} matinput="" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <svg style={{width:24,height:24}} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path d="M5.66634 5.6665H28.333C29.8913 5.6665 31.1663 6.9415 31.1663 8.49984V25.4998C31.1663 27.0582 29.8913 28.3332 28.333 28.3332H5.66634C4.10801 28.3332 2.83301 27.0582 2.83301 25.4998V8.49984C2.83301 6.9415 4.10801 5.6665 5.66634 5.6665Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M31.1663 8.5L16.9997 18.4167L2.83301 8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <em></em>
                                            { <>{validateEmail(email) ? <></> :<span style={{color: "red"}}>{t('warningEmail')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("password21")}</p>
                                            <input type={type} value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="********" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <span class="flex justify-around items-center" onClick={handleToggle}>
                                                {icon === 'on' ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                            </span>
                                            <em></em>
                                            { <>{password.length>8 ? <></> :<span style={{color: "red"}}>{t('warningPass')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("ConfirmPassword")}</p>
                                            <input type={type2} value={confirm} onChange={(e)=>{setConfirm(e.target.value)}} placeholder="********" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <span class="flex justify-around items-center" onClick={handleToggle2}>
                                                {icon2 === 'on' ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                            </span>
                                            <em></em>
                                            { <>{password===confirm ? <></> :<span style={{color: "red"}}>{t('warningPass2')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("birthDate21")}</p>
                                            <input type="date" onChange={(e)=>{setBirthdate(e.target.value)}} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            { <>{ isAtLeast16YearsOld(birthdate) ? <></> :<span style={{color: "red"}}>{t('AgeWarning')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p> </p>
                                            <input type="text" onChange={(e)=>{setBankName(e.target.value);}} placeholder={t('placeHolderbankname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <BadgeIcon className='mt-3'/>
                                            { <>{bankName.length >= 3 ? <></> :<span style={{color: "red"}}>{t('warningBankName')}</span> }</>}
                                        </div>

                                        <div className="form-area">
                                            <p>IBAN *</p>
                                            <input 
                                                className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"
                                                type="text"
                                                maxLength={26} 
                                                onChange={handleChange}
                                                value={iban}
                                                placeholder="TR************************" 
                                            />
                                            <CreditCardIcon className='mt-3'/>
                                            { <>{iban.length === 26 ? <></> :<span style={{color: "red"}}>{t('warningIban2')}</span> }</>}
                                        </div>

                                        <div className="form-area">
                                            <p>T.C *</p>
                                            <input type="text" maxLength={11} value={NID} onChange={handleTCChange} placeholder="55798050924"  className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <FingerprintIcon className='mt-3'/>
                                            { <>{NID.length === 11 ? <></> :<span style={{color: "red"}}>{t('warningID')}</span> }</>}
                                        </div>
                                        <div className="buttons between">
                                            <a href='../forget-pass' className="forgot-password">{t('forgotPassword')}</a>
                                            <button onClick={()=>{
                                                if(allowSubmit()){
                                                    Signup();
                                                }
                                            }} className="button brown large">{t('signup')}</button>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{success ? t("SuccessTitle"):t('WarningTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("close")}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
    )
}
